import { template as template_97b9c8cb939a4b49b34334a88f0c3ecb } from "@ember/template-compiler";
const FKText = template_97b9c8cb939a4b49b34334a88f0c3ecb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
